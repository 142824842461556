import { state, style, animate, trigger, transition } from '@angular/animations';

export const appearAnimation = trigger('appearAnimation', [

  state('*', style({
    opacity: 0,
    transform: 'translateY(2rem)'
  })),
  state('visible', style({
    opacity: 1,
    transform: 'translateY(0)'
  })),
  transition('* => visible', [
    animate('1s')
  ])
]);