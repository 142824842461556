<div class="container comp-main ">
  <app-headline headline="{{ 'workTitle' | translate }}" id="projekter">
    {{ 'workSubtitle' | translate }}
  </app-headline>
  <div class="work-listing">
    <div class="row gx-5" id="showcase0">
      <div class="case-description col-lg-5 order-1">
        <div #e0 (appear)="e0.classList.add('visible')" class="work-headline-container ani-appear">
          <h3>{{ 'workCase0.title' | translate }}</h3>
          <p>{{ 'workCase0.description' | translate }}</p>
          <ul class="work-checklist">
            <li>{{ 'workCase0.bullet.one' | translate }}</li>
            <li>{{ 'workCase0.bullet.two' | translate }}</li>
            <li>{{ 'workCase0.bullet.three' | translate }}</li>
          </ul>
          <div class="site-link-container">
            <a href="https://www.billeje.info/billeje/europa/danmark/billund-lufthavn/" rel="nofollow" target="_blank"><i class="fas fa-external-link-alt"></i>{{ 'workOpenWebsite' | translate }}</a>
            <p>{{ 'workOpensInNewWindow' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="case-image full-bleed reverse col-lg-7 order-lg-5">
        <img src="/assets/img/billeje-info.jpg" alt="Udviklerprojekt for Restaurant Taigoo" height="647" width="723">
      </div>
    </div>
    <div class="row gx-5" id="showcase1">
      <div class="col-lg-7 case-image full-bleed">
        <img src="/assets/img/thailandtours.jpg" alt="Thailand Tours" height="647" width="723">
      </div>
      <div class="case-description col-lg-5">
        <div #e1 (appear)="e1.classList.add('visible')" class="work-headline-container ani-appear">
          <h3>{{ 'workCase1.title' | translate }}</h3>
          <p>{{ 'workCase1.description' | translate }}</p>
          <ul class="work-checklist">
            <li>{{ 'workCase1.bullet.one' | translate }}</li>
            <li>{{ 'workCase1.bullet.two' | translate }}</li>
            <li>{{ 'workCase1.bullet.three' | translate }}</li>
          </ul>
          <div class="site-link-container">
            <a href="https://www.thailandtours.dk" target="_blank"><i class="fas fa-external-link-alt"></i>{{ 'workOpenWebsite' | translate }}</a>
            <p>{{ 'workOpensInNewWindow' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row gx-5" id="showcase2">
      <div class="case-description col-lg-5 order-1">
        <div #e2 (appear)="e2.classList.add('visible')" class="work-headline-container ani-appear">
          <h3>{{ 'workCase2.title' | translate }}</h3>
          <p>{{ 'workCase2.description' | translate }}</p>
          <ul class="work-checklist">
            <li>{{ 'workCase2.bullet.one' | translate }}</li>
            <li>{{ 'workCase2.bullet.two' | translate }}</li>
            <li>{{ 'workCase2.bullet.three' | translate }}</li>
          </ul>
          <div class="site-link-container">
            <a href="https://randers-netavis.dk/" rel="nofollow" target="_blank"><i class="fas fa-external-link-alt"></i>{{ 'workOpenWebsite' | translate }}</a>
            <p>{{ 'workOpensInNewWindow' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="case-image full-bleed reverse col-lg-7 order-lg-5">
        <img src="/assets/img/netavis.jpg" alt="MichaelKvist.com landing page" height="647" width="723">
      </div>
    </div>
  </div>
</div>
