import { rendererTypeName } from '@angular/compiler';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { appearAnimation} from 'src/app/animations/appear.animation';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss'],
  animations: [ appearAnimation ]
})
export class WorkComponent implements OnInit {
  visible = false;
  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  onAppear(element: ElementRef) {
    this.renderer.addClass(element,'visible');
  }

}
