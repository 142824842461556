<div class="container">
  <div class="row">
    <div class="col-lg-6 mb-5 mb-lg-0">
      <h3 id="kontakt" class="h4">{{ 'contactTitle' | translate }}</h3>
      <p>{{ 'contactParagraph' | translate }}</p>
      <hr>
      <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
        <div id="email-con">
          <label for="email" class="form-label">{{ 'contactLabelEmail.title' | translate }}</label>
          <input type="email" class="form-control" name="email" id="email" required ngModel email #email="ngModel" [ngModelOptions]="{ updateOn: 'blur' }">
          <p class="sub-disclaimer">{{ 'contactLabelEmail.submessage' | translate }}</p>
          <div [hidden]="email.valid || email.pristine || !contactForm.form.enabled" class="alert alert-danger">{{ 'contactLabelEmail.errorInvalid' | translate }}</div>
        </div>
        <div id="message-con">
          <label for="message" class="form-label">{{ 'contactLabelMessage.title' | translate }}</label>
          <textarea name="message" class="form-control" id="message" cols="30" rows="10" required ngModel #message="ngModel"></textarea>
          <div [hidden]="message.valid || message.pristine || !contactForm.form.enabled" class="alert alert-danger">{{ 'contactLabelMessage.errorEnterMessage' | translate }}</div>
        </div>
        <div [hidden]="serverState != 'error'" class="alert alert-danger">{{ 'contactSendMessageBtn.error' | translate }}</div>
        <div [hidden]="serverState != 'success'" class="alert alert-success">{{ 'contactSendMessageBtn.success' | translate }}</div>
        <div class="submit-button"><button type="submit" class="btn btn-primary" [disabled]="!contactForm.form.valid" #submitBtn>{{ 'contactSendMessageBtn.title' | translate }}</button></div>
      </form>
    </div>
    <div class="col-lg-4 offset-lg-2">
      <ul class="contact-info-list">
        <li class="name">Steven Mønsted Nielsen<span class="title">{{ 'contactOwnerOf' | translate }} <span style="font-weight: 700">Pankado</span></span></li>
        <li class="email"><a href="mailto:kontakt@pankado.com"><i class="far fa-envelope"></i>kontakt@pankado.com</a></li>
      </ul>
      <ul class="company-info">
        <li class="address">{{ 'companyAddress' | translate }}</li>
        <li class="vat-number">TIN: 5-0110-01-159654</li>
      </ul>
    </div>
  </div>
</div>