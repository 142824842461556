import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(private translate: TranslateService, private route: ActivatedRoute) {}

  toggleLanguage() {
    this.translate.currentLang === 'japanese' ? this.translate.use('da') : this.translate.use('japanese');
  }
}
