import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from './contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  @ViewChild('submitBtn') submitBtn: ElementRef;
  
  serverState: string = ""
  constructor(private renderer: Renderer2, private contactService: ContactService, private translate: TranslateService) { }

  onSubmit(contactForm: NgForm) {
    const origbtnText = this.submitBtn.nativeElement.innerHTML;
    contactForm.form.disable();

    this.renderer.setProperty(this.submitBtn.nativeElement, 'innerHTML', this.translate.instant('contactSendMessageBtn.stateSending'));
    this.contactService.sendMail(contactForm.value).subscribe(
      () => {
        this.renderer.setProperty(this.submitBtn.nativeElement, 'innerHTML', this.translate.instant('contactSendMessageBtn.stateSuccess'));
        contactForm.reset();
        this.serverState = 'success';
      },
      () => {
        contactForm.form.enable();
        this.renderer.setProperty(this.submitBtn.nativeElement, 'innerHTML', origbtnText);
        this.serverState = 'error';
      }
    );
  }
}

        // contactForm.form.enable();
        // this.renderer.setProperty(this.submitBtn.nativeElement, 'innerHTML', origbtnText);
        // this.serverState = 'error';