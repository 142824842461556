<div class="host-container negative-space">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-headline headline="{{ 'skillsTitle' | translate }}" id="kompetencer" class="negative-space">
          {{ 'skillsSubtitle' | translate }}
        </app-headline>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-8 offset-lg-2 col-xl-12 offset-xl-0">
        <div class="skills-listing full-bleed">
          <div #c1 (appear)="c1.classList.add('visible')" class="skills-container ani-appear">
            <div class="header">
              <h3 class="h4"><i class="fas fa-layer-group"></i>{{ 'skillsPanels.one.title' | translate}}</h3>
            </div>
            <div class="subheader">{{ 'skillsPanels.one.subtitle' | translate}}</div>
            <p>{{ 'skillsPanels.one.paragraph' | translate}}</p>
          </div>
          <div #c2 (appear)="c2.classList.add('visible')" class="skills-container ani-appear delay-1">
            <div class="header">
              <h3 class="h4"><i class="fas fa-layer-group"></i>{{ 'skillsPanels.two.title' | translate}}</h3>
            </div>
            <div class="subheader">{{ 'skillsPanels.two.subtitle' | translate}}</div>
            <p>{{ 'skillsPanels.two.paragraph' | translate}}</p>
          </div>
          <div #c3 (appear)="c3.classList.add('visible')" class="skills-container ani-appear delay-2">
            <div class="header">
              <h3 class="h4"><i class="fas fa-layer-group"></i>{{ 'skillsPanels.three.title' | translate}}</h3>
            </div>
            <div class="subheader">{{ 'skillsPanels.three.subtitle' | translate}}</div>
            <p>{{ 'skillsPanels.three.paragraph' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="techniques-container full-bleed">
          <div class="row">
            <div class="col-10 offset-1 col-sm-6 offset-sm-3">
              <div>
                <h3 class="h2">{{ 'skillsFrameworkTitle' | translate}}</h3>
                <div class="tech-bulletlist">
                  <div>
                    <img src="/assets/svg/wordpress-logo.svg" alt="wordpress logo">
                    <p>Wordpress</p>
                  </div>
                  <div>
                    <img src="/assets/svg/woocommerce-logo.svg" alt="woocommerce logo">
                    <p>Woocommerce</p>
                  </div>
                  <div>
                    <img src="/assets/svg/angular-logo.svg" alt="angular logo">
                    <p>Angular</p>
                  </div>
                  <div>
                    <img src="/assets/svg/bootstrap-logo.svg" alt="bootstrap logo">
                    <p>Bootstrap</p>
                  </div>
                  <div>
                    <img src="/assets/svg/aws-logo.svg" alt="amazon web services logo">
                    <p>Amazon Web Services</p>
                  </div>
                  <div>
                    <img src="/assets/svg/unity-logo.svg" alt="unity logo">
                    <p>Unity</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>