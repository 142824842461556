import { HttpParams } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService, private route: ActivatedRoute) {
    translate.addLangs(['da, japanese']);
    translate.setDefaultLang('da');
  }

  ngOnInit(): void {
    const langQuery$ = this.route.queryParamMap.pipe(map((params: ParamMap) => params.get('lang')));
    langQuery$.subscribe(langParam => {
      if (langParam === 'jp') {
        this.translate.use('japanese');
      }
    });
  }
}
