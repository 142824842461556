import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private url = 'https://pankado.com:3000/mail';
  constructor(private http: HttpClient) {}

  sendMail(formData: {email: string, message: string}): Observable<any>  {
    return this.http.post<any>(this.url,formData, {observe: 'response'});
  }
}
