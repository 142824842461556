<div class="hero-container negative-space md-5">
  <div class="backdrop"></div>
  <div class="hero-image">
  </div>
  <div class="container">
    <div class="row">
      <div #heroText (appear)="heroText.classList.add('visible')" class="col-xl-5 main-text ani-appear">
        <h1 class="display-5">{{ 'homeTitle' | translate }}</h1>
        <div class="description">
          <p>{{ 'homeParagraph' | translate }}</p>
          <p>{{ 'homeParagraph2' | translate }}</p>
          <p>{{ 'homeParagraph3' | translate }}</p>
        </div>
        <div class="cta-buttons">
          <a routerLink="/" fragment="kontakt" class="btn btn-primary cta-btn">{{ 'homeContactBtn' | translate }}</a>
          <a routerLink="/" fragment="projekter" class="btn view-projects-btn btn-secondary">{{ 'homeSeeProjectsBtn' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
