<header>
  <nav class="navbar navbar-expand">
    <div class="container">
      <a href="#" class="navbar-brand">PANKADO</a>
      <div class="collapse navbar-collapse" id="navbarContent">
        <ul class="navbar-nav ms-auto">
          <div class="nav-items">
            <li class="nav-item d-none d-md-inline"><a class="nav-link" routerLink="/" fragment="projekter"><span>{{ 'headerNavProjects' | translate }}</span></a></li>
            <li class="nav-item"><a class="nav-link" routerLink="/" fragment="kompetencer"><span>{{ 'headerNavSkills' | translate }}</span></a></li>
            <li class="nav-item d-none d-md-inline"><a class="nav-link" routerLink="/" fragment="kontakt"><span>{{ 'headerNavContact' | translate }}</span></a></li>
            <li id="changeLang"><a class="nav-link"(click)="toggleLanguage()"><span>{{ 'language' | translate }}</span></a></li>
          </div>
        </ul>
      </div>
    </div>
  </nav>
</header>